/* eslint-disable global-require */

exports.categories = [
    {
        "slug": "all",
        "name": "Todos",
        "icon": require("../images/categories/all.svg").default,
        "backgroundClass": "bg-primary",
        "backgroundActiveClass": "bg-darkpurple"
    },
    {
        "slug": "dinosaur",
        "name": "Dinossauros",
        "icon": require("../images/categories/dinosaur.svg").default,
        "backgroundClass": "bg-primary",
        "backgroundActiveClass": "bg-primary"
    },
    {
        "slug": "doll",
        "name": "Bonecas",
        "icon": require("../images/categories/doll.svg").default,
        "backgroundClass": "bg-primary",
        "backgroundActiveClass": "bg-primary"
    },
    {
        "slug": "car",
        "name": "Carrinhos",
        "icon": require("../images/categories/car.svg").default,
        "backgroundClass": "bg-primary",
        "backgroundActiveClass": "bg-primary"
    },
    {
        "slug": "adventure",
        "name": "Aventura",
        "icon": require("../images/categories/adventure.svg").default,
        "backgroundClass": "bg-primary",
        "backgroundActiveClass": "bg-primary"
    }
]