/* eslint-disable global-require */

exports.products = [
    {
        name: "Baby Dodói",
        category: "girl",
        isNew: true,
        description: "Prepare-se para cuidar, curar e amar com nossa boneca bebê e seu kit médico, o conjunto perfeito para pequenos doutores em treinamento!",
        slug: "baby-dodoi",
        logo: "products/baby-dodoi/logo.png",
        lamina: ["products/baby-dodoi/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/baby-dodoi/box.png"
            }
        ],
        feature: "products/baby-dodoi/featured.png",
        downloadUrl: "products/baby-dodoi/baby-dodoi.pdf",
        skus: [
            {
                "sku": "9600",
                "name": "Baby Dodói"
            }
        ],
        info: {
            height: "21cm",
            measures: "40,5x9x25cm",
            fitsInBox: 6,
            weight: "2,4kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Lalá Peniquinho",
        category: "girl",
        isNew: true,
        description: "Companheira de aprendizado e diversão! Lalá Peniquinho já vem com mamadeira e seu próprio penico para tornar a jornada do desfralde ainda mais especial e acolhedora!",
        slug: "lala-peniquinho",
        logo: "products/lala-peniquinho/logo.png",
        lamina: ["products/lala-peniquinho/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/lala-peniquinho/box.png"
            }
        ],
        feature: "products/lala-peniquinho/featured.png",
        downloadUrl: "products/lala-peniquinho/lala-peniquinho.pdf",
        skus: [
            {
                "sku": "9605",
                "name": "Lalá Peniquinho"
            }
        ],
        info: {
            height: "40cm",
            measures: "30x11x53cm",
            fitsInBox: 6,
            weight: "4,1kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Lovie Dog Pintura",
        category: "girl",
        isNew: true,
        description: "Deixe a imaginação correr solta com nosso adorável cachorrinho para pintar, criando momentos inesquecíveis de diversão e arte!",
        slug: "lovie-dog-pintura",
        logo: "products/lovie-dog-pintura/logo.png",
        lamina: ["products/lovie-dog-pintura/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/lovie-dog-pintura/box.png"
            }
        ],
        feature: "products/lovie-dog-pintura/featured.png",
        downloadUrl: "products/lovie-dog-pintura/lovie-dog-pintura.pdf",
        skus: [
            {
                "sku": "9606",
                "name": "Lovie Dog Pintura"
            }
        ],
        info: {
            height: "12,5cm",
            measures: "26x8x20cm",
            fitsInBox: 12,
            weight: "3,3kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll", "adventure"]
    },
    {
        name: "Chef Lis",
        category: "girl",
        isNew: true,
        description: "Deixe a imaginação dos pequenos voar enquanto criam aventuras culinárias com a Boneca Chef Lis, pronta para preparar os pratos mais deliciosos e divertidos!",
        slug: "chef-lis",
        logo: "products/chef-lis/logo.png",
        lamina: ["products/chef-lis/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/chef-lis/box.png"
            }
        ],
        feature: "products/chef-lis/featured.png",
        downloadUrl: "products/chef-lis/chef-lis.pdf",
        skus: [
            {
                "sku": "9603",
                "name": "Chef Lis"
            }
        ],
        info: {
            height: "30cm",
            measures: "24,5x9x40cm",
            fitsInBox: 6,
            weight: "2,8kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },



    {
        name: "My Uni",
        category: "girl",
        isNew: false,
        description: "Deixe a imaginação correr solta com o My Uni, o pônei para pintar e personalizar como quiser.",
        slug: "my-uni",
        logo: "products/my-uni/logo.png",
        lamina: ["products/my-uni/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/my-uni/box.png"
            }
        ],
        feature: "products/my-uni/featured.png",
        downloadUrl: "products/my-uni/my-uni.pdf",
        skus: [
            {
                "sku": "9595",
                "name": "My Uni"
            }
        ],
        info: {
            height: "16cm",
            measures: "26x8x20cm",
            fitsInBox: 12,
            weight: "4,0kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Lovie Dogs",
        category: "girl",
        isNew: false,
        description: "Faça seu pet brilhar com Lovie Dogs, acompanha acessórios para criar penteados incríveis em seu fiel companheiro!",
        slug: "lovie-dogs",
        logo: "products/lovie-dogs/logo.png",
        lamina: ["products/lovie-dogs/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/lovie-dogs/box.png"
            }
        ],
        feature: "products/lovie-dogs/featured.png",
        downloadUrl: "products/lovie-dogs/lovie-dogs.pdf",
        skus: [
            {
                "sku": "9596",
                "name": "Lovie Dogs"
            }
        ],
        info: {
            height: "12,5cm",
            measures: "29,5x11x20,5cm",
            fitsInBox: 8,
            weight: "3,3kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Love Babie Banheirinha",
        category: "girl",
        isNew: false,
        description: "Bonecas fofinhas em vinil que acompanham banheira e toalha. Alegria garantida até debaixo d'água!",
        slug: "love-babie-banheirinha",
        logo: "products/love-babie-banheirinha/logo.png",
        lamina: ["products/love-babie-banheirinha/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/love-babie-banheirinha/box.png"
            }
        ],
        feature: "products/love-babie-banheirinha/featured.png",
        downloadUrl: "products/love-babie-banheirinha/love-babie-banheirinha.pdf",
        skus: [
            {
                "sku": "9575",
                "name": "Love Babie Banheirinha | Loira"
            },
            {
                "sku": "9576",
                "name": "Love Babie Banheirinha | Negra"
            },
        ],
        info: {
            height: "19cm",
            measures: "21x12x18cm",
            fitsInBox: 12,
            weight: "4,8kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Love Babie Aninha",
        category: "girl",
        isNew: false,
        description: "Love Babie Aninha é uma bonequinha assustadoramente linda.",
        slug: "love-babie-aninha",
        logo: "products/love-babie-aninha/logo.png",
        lamina: ["products/love-babie-aninha/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/love-babie-aninha/box.png"
            }
        ],
        feature: "products/love-babie-aninha/featured.png",
        downloadUrl: "products/love-babie-aninha/love-babie-aninha.pdf",
        skus: [
            {
                "sku": "9597",
                "name": "Love Babie | Aninha"
            }
        ],
        info: {
            height: "19cm",
            measures: "15x8,5x24cm",
            fitsInBox: 12,
            weight: "3,8kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Love Babie Papinha",
        category: "girl",
        isNew: false,
        description: "Com a Love Babie, a criança se diverte até na hora da papinha!",
        slug: "love-babie-papinha",
        logo: "products/love-babie-papinha/logo.png",
        lamina: ["products/love-babie-papinha/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/love-babie-papinha/box.png"
            }
        ],
        feature: "products/love-babie-papinha/featured.png",
        downloadUrl: "products/love-babie-papinha/love-babie-papinha.pdf",
        skus: [
            {
                "sku": "9579",
                "name": "Love Babie | Papinha"
            }
        ],
        info: {
            height: "19cm",
            measures: "29x8,5x24,5cm",
            fitsInBox: 12,
            weight: "5,1kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Love Babie Balancinho",
        category: "girl",
        isNew: false,
        description: "Uma bonequinha suuuper fofa que adora brincar no seu balanço",
        slug: "love-babie-balancinho",
        logo: "products/love-babie-balancinho/logo.png",
        lamina: ["products/love-babie-balancinho/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/love-babie-balancinho/box.png"
            }
        ],
        feature: "products/love-babie-balancinho/featured.png",
        downloadUrl: "products/love-babie-balancinho/love-babie-balancinho.pdf",
        skus: [
            {
                "sku": "9588",
                "name": "Love Babie | Balancinho"
            }
        ],
        info: {
            height: "20,5cm",
            measures: "20x13,5x25,5cm",
            fitsInBox: 8,
            weight: "3,2kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Love Babie Amiguinha",
        category: "girl",
        isNew: false,
        description: "Lindas bonecas macias em 2 modelos: negra e branca. A criança vai amar!",
        slug: "love-babie-amiguinha",
        logo: "products/love-babie-amiguinha/logo.png",
        lamina: ["products/love-babie-amiguinha/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/love-babie-amiguinha/box.png"
            }
        ],
        feature: "products/love-babie-amiguinha/featured.png",
        downloadUrl: "products/love-babie-amiguinha/love-babie-amiguinha.pdf",
        skus: [
            {
                "sku": "9573",
                "name": "Love Babie | Amiguinha Loira"
            },
            {
                "sku": "9591",
                "name": "Love Babie | Amiguinha Morena"
            },
            {
                "sku": "9574",
                "name": "Love Babie | Amiguinha Negra"
            }
        ],
        info: {
            height: "19cm",
            measures: "15x8,5x24cm",
            fitsInBox: 12,
            weight: "3,8kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Love Babie Scooter",
        category: "girl",
        isNew: false,
        description: "São macias, em vinil e a boneca adora passear em sua linda scooter.",
        slug: "love-babie-scooter",
        logo: "products/love-babie-scooter/logo.png",
        lamina: ["products/love-babie-scooter/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/love-babie-scooter/box.png"
            }
        ],
        feature: "products/love-babie-scooter/featured.png",
        downloadUrl: "products/love-babie-scooter/love-babie-scooter.pdf",
        skus: [
            {
                "sku": "9578",
                "name": "Love Babie | Scooter"
            }
        ],
        info: {
            height: "19cm",
            measures: "30x8,5x21cm",
            fitsInBox: 8,
            weight: "3,8kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Meu Amigo Unicórnio",
        category: "girl",
        isNew: false,
        description: "Fofinhos unicórnios para brincar e pentear.",
        slug: "meu-amigo-unicornio",
        logo: "products/meu-amigo-unicornio/logo.png",
        skuPageChange: true,
        lamina: [
            "products/meu-amigo-unicornio/lamina-1.png",
            "products/meu-amigo-unicornio/lamina-2.png"
        ],
        box: [
            {
                "page": 1,
                "image":  "products/meu-amigo-unicornio/box-1.png"
            },
            {
                "page": 2,
                "image":  "products/meu-amigo-unicornio/box-2.png"
            }
        ],
        feature: "products/meu-amigo-unicornio/featured.png",
        downloadUrl: "products/meu-amigo-unicornio/meu-amigo-unicornio.zip",
        skus: [
            {
                "sku": "9540",
                "name": "Meu Amigo Unicórnio | Kit Beleza",
                info: {
                    height: "16cm",
                    measures: "26x8x20cm",
                    fitsInBox: 12,
                    weight: "3,5kg"
                },
                "page": 1,
            },
            {
                "sku": "9541",
                "name": "Meu Amigo Unicórnio | Boneca",
                info: {
                    height: "16cm",
                    measures: "26x8x20cm",
                    fitsInBox: 12,
                    weight: "3,9kg"
                },
                "page": 2
            }
        ],
        related: [
            {
                "name": "Meu Amigo Unicórnio",
                "slug": "/produtos/meu-amigo-unicornio",
                "box": "products/meu-amigo-unicornio/box.png"
            },
            {
                "name": "Leela",
                "slug": "/produtos/leela",
                "box": "products/leela/box.png"
            },
            {
                "name": "Fofy's",
                "slug": "/produtos/fofys",
                "box": "products/fofys/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Diliany Baby",
        category: "girl",
        isNew: false,
        description: "Linda boneca que pode vir na caixa ou na solapa. Opções de roupa: vestido ou calcinha e babador.",
        slug: "diliany-baby",
        logo: "products/diliany-baby/logo.png",
        lamina: ["products/diliany-baby/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/diliany-baby/box.png"
            }
        ],
        feature: "products/diliany-baby/featured.png",
        downloadUrl: "products/diliany-baby/diliany-baby.pdf",
        skus: [
            {
                "sku": "9553",
                "name": "Dailiany Baby | Solapa",
                info: {
                    height: "40cm",
                    measures: "-",
                    fitsInBox: 14,
                    weight: "2,2kg"
                },
            },
            {
                "sku": "9232",
                "name": "Dailiany Baby | Vestido"
            }
        ],
        info: {
            height: "40cm",
            measures: "24x11,5x48,2cm",
            fitsInBox: 6,
            weight: "2,8kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Little Shark",
        isNew: false,
        description: "Divertidos tubarões, macios e leves para brincar até no banho.",
        slug: "little-shark",
        logo: "products/little-shark/logo.png",
        lamina: ["products/little-shark/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/little-shark/box-1.png"
            },
            {
                isNew: true,
                "page": 1,
                "image":  "products/little-shark/box-2.png"
            }
        ],
        feature: "products/little-shark/featured.png",
        downloadUrl: "products/little-shark/little-shark.pdf",
        skus: [
            {
                "sku": "9604",
                "name": "Little Shark | Cinta",
                info: {
                    height: "19cm",
                    measures: "19x14x21cm",
                    fitsInBox: 9,
                    weight: "1,5kg"
                }
            },
            {
                "sku": "9570",
                "name": "Little Shark | Azul"
            },
            {
                "sku": "9571",
                "name": "Little Shark | Amarelo"
            },
            {
                "sku": "9572",
                "name": "Little Shark | Rosa"
            },
        ],
        info: {
            height: "19cm",
            measures: "25x13x12,5cm",
            fitsInBox: 9,
            weight: "3,0kg"
        },
        related: [
            {
                "name": "Meu Amigo Unicórnio",
                "slug": "/produtos/meu-amigo-unicornio",
                "box": "products/meu-amigo-unicornio/box.png"
            },
            {
                "name": "Leela",
                "slug": "/produtos/leela",
                "box": "products/leela/box.png"
            },
            {
                "name": "Fofy's",
                "slug": "/produtos/fofys",
                "box": "products/fofys/box.png"
            }
        ],
        categories: ["all", "adventure"]
    },
    {
        name: "Baby Doctor",
        category: "girl",
        isNew: false,
        description: "Boneca que acompanha diversos acessórios para a criança cuidar de um bebê.",
        slug: "baby-doctor",
        logo: "products/baby-doctor/logo.png",
        lamina: ["products/baby-doctor/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/baby-doctor/box.png"
            }
        ],
        feature: "products/baby-doctor/featured.png",
        downloadUrl: "products/baby-doctor/baby-doctor.pdf",
        skus: [
            {
                "sku": "9526",
                "name": "Baby Doctor | Loira"
            }
        ],
        info: {
            height: "33,5cm",
            measures: "24,5x9x40,5cm",
            fitsInBox: 6,
            weight: "2,9kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Snow Princess",
        category: "girl",
        isNew: false,
        description: "Inspirada nos reinos gelados, brincar com a Snow Princess é uma aventura.",
        slug: "snow-princess",
        logo: "products/snow-princess/logo.png",
        lamina: ["products/snow-princess/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/snow-princess/box.png"
            }
        ],
        feature: "products/snow-princess/featured.png",
        downloadUrl: "products/snow-princess/snow-princess.pdf",
        skus: [
            {
                "sku": "9567",
                "name": "Snow Princess"
            }
        ],
        info: {
            height: "31cm",
            measures: "12,5x5,5x33,5cm",
            fitsInBox: 12,
            weight: "2,1kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Lizzy",
        category: "girl",
        isNew: false,
        description: "A princesa que sempre estará com você, em todas as suas aventuras!",
        slug: "lizzy",
        logo: "products/lizzy/logo.png",
        lamina: ["products/lizzy/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/lizzy/box.png"
            }
        ],
        feature: "products/lizzy/featured.png",
        downloadUrl: "products/lizzy/lizzy.pdf",
        skus: [
            {
                "sku": "9598",
                "name": "Lizzy"
            }
        ],
        info: {
            height: "51cm",
            measures: "26x9x56cm",
            fitsInBox: 6,
            weight: "3,5kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Baby Xixizinho",
        category: "girl",
        isNew: false,
        description: "Baby Xixizinho acompanha mamadeira e fralda e fax xixi. É super divertido!",
        slug: "baby-xixizinho",
        logo: "products/baby-xixizinho/logo.png",
        lamina: ["products/baby-xixizinho/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/baby-xixizinho/box.png"
            }
        ],
        feature: "products/baby-xixizinho/featured.png",
        downloadUrl: "products/baby-xixizinho/baby-xixizinho.pdf",
        skus: [
            {
                "sku": "9528",
                "name": "Baby Xixizinho | Loira"
            }
        ],
        info: {
            height: "33,5cm",
            measures: "24,5x9x40,5cm",
            fitsInBox: 6,
            weight: "2,7kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Kit 4 Fraldinhas",
        category: "girl",
        isNew: false,
        description: "Compatível com outros modelos de boneca.",
        slug: "kit-fraldinhas",
        logo: "products/kit-fraldinha/logo.png",
        lamina: ["products/kit-fraldinha/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/kit-fraldinha/box.png"
            }
        ],
        feature: "products/kit-fraldinha/featured.png",
        downloadUrl: "products/kit-fraldinha/kit-fraldinha.pdf",
        skus: [
            {
                "sku": "9530",
                "name": "Kit 4 Fraldinhas"
            }
        ],
        info: {
            height: "-",
            measures: "13x4x15,5cm",
            fitsInBox: 12,
            weight: "0,7kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Bibia Frases",
        category: "girl",
        isNew: false,
        description: "A boneca Bibia fala diversas frases diferentes, criando uma interação super importante com a criança.",
        slug: "bibia-frases",
        logo: "products/bibia-frases/logo.png",
        lamina: ["products/bibia-frases/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/bibia-frases/box.png"
            }
        ],
        feature: "products/bibia-frases/featured.png",
        downloadUrl: "products/bibia-frases/bibia-frases.pdf",
        skus: [
            {
                "sku": "5018",
                "name": "Bibia Frases"
            }
        ],
        info: {
            height: "34cm",
            measures: "16,5x9x40,5cm",
            fitsInBox: 9,
            weight: "3,9kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Aventura Snow",
        category: "girl",
        isNew: false,
        description: "Lindas bonecas da Aventura Snow. Uma aventura congelante!",
        slug: "aventura-snow",
        logo: "products/aventura-snow/logo.png",
        lamina: ["products/aventura-snow/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/aventura-snow/box.png"
            }
        ],
        feature: "products/aventura-snow/featured.png",
        downloadUrl: "products/aventura-snow/aventura-snow.pdf",
        skus: [
            {
                "sku": "5001",
                "name": "Aventura Snow | Anne"
            },
            {
                "sku": "5002",
                "name": "Aventura Snow | Elis"
            }
        ],
        info: {
            height: "51cm",
            measures: "26x9x56cm",
            fitsInBox: 6,
            weight: "3,9kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Fofy's",
        category: "girl",
        isNew: false,
        description: "A bonequinha Fofy's é super fofinha e acompanha um lindo cachecol.",
        slug: "fofys",
        logo: "products/fofys/logo.png",
        lamina: ["products/fofys/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/fofys/box.png"
            }
        ],
        feature: "products/fofys/featured.png",
        downloadUrl: "products/fofys/fofys.pdf",
        skus: [
            {
                "sku": "5100",
                "name": "Fofy's | Rosa"
            }
        ],
        info: {
            height: "17cm",
            measures: "15,6x9,7x20cm",
            fitsInBox: 12,
            weight: "3,6kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Lalinhas Fashion",
        category: "girl",
        isNew: false,
        description: "As lalinhas vem na caixa ou na solapa, com opções de cabelos diferentes e sapatinho.",
        slug: "lalinhas-fashion",
        logo: "products/lalinhas-fashion/logo.png",
        lamina: ["products/lalinhas-fashion/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/lalinhas-fashion/box.png"
            }
        ],
        feature: "products/lalinhas-fashion/featured.png",
        downloadUrl: "products/lalinhas-fashion/lalinhas-fashion.pdf",
        skus: [
            {
                "sku": "9601",
                "name": "Lalinhas Fashion | Caixa",
                info: {
                    height: "23cm",
                    measures: "12x5,5x30,5cm",
                    fitsInBox: 12,
                    weight: "3,0kg"
                }
            },
            {
                "sku": "9602",
                "name": "Lalinhas Fashion | Solapa"
            },
        ],
        info: {
            height: "23cm",
            measures: "-",
            fitsInBox: 25,
            weight: "2,2kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Miguxa Baby",
        category: "girl",
        isNew: false,
        description: "Super companheira e delicada, a Miguxa vai se tornar sua melhor amiga.",
        slug: "miguxa-baby",
        logo: "products/miguxa-baby/logo.png",
        lamina: ["products/miguxa-baby/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/miguxa-baby/box.png"
            }
        ],
        feature: "products/miguxa-baby/featured.png",
        downloadUrl: "products/miguxa-baby/miguxa-baby.pdf",
        skus: [
            {
                "sku": "9583",
                "name": "Miguxa Baby"
            }
        ],
        info: {
            height: "34cm",
            measures: "16,5x9x40,5cm",
            fitsInBox: 9,
            weight: "3,5kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Lary Fashion",
        category: "girl",
        isNew: false,
        description: "Super estilosa, pode vir na caixa ou solapa, com 3 cores de cabelos diferentes e sapatos.",
        slug: "lary-fashion",
        logo: "products/lary-fashion/logo.png",
        lamina: ["products/lary-fashion/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/lary-fashion/box.png"
            }
        ],
        feature: "products/lary-fashion/featured.png",
        downloadUrl: "products/lary-fashion/lary-fashion.pdf",
        skus: [
            {
                "sku": "5303",
                "name": "Lary Fashion | Solapa",
                info: {
                    height: "29cm",
                    measures: "-",
                    fitsInBox: 50,
                    weight: "3,0kg"
                },
            },
            {
                "sku": "5300",
                "name": "Lary Fashion | Loira"
            },
            {
                "sku": "5301",
                "name": "Lary Fashion | Morena"
            },
            {
                "sku": "5302",
                "name": "Lary Fashion | Color"
            }
        ],
        info: {
            height: "29cm",
            measures: "10x5x33,5cm",
            fitsInBox: 24,
            weight: "2,3kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Era Uma Vez",
        category: "girl",
        isNew: false,
        description: "Inspiradas nos principais contos infantis, vem com uma roupinha charmosa e sapatinhos.",
        slug: "era-uma-vez",
        logo: "products/era-uma-vez/logo.png",
        lamina: ["products/era-uma-vez/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/era-uma-vez/box.png"
            }
        ],
        feature: "products/era-uma-vez/featured.png",
        downloadUrl: "products/era-uma-vez/era-uma-vez.pdf",
        skus: [
            {
                "sku": "9504 / 9505",
                "name": "Coleção Era Uma Vez"
            }
        ],
        info: {
            height: "31cm",
            measures: "13,5x5x33,5cm",
            fitsInBox: 18,
            weight: "2,8kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Beijokinhas",
        category: "girl",
        isNew: false,
        description: "A queridinha boneca Beijokinhas apresenta vários modelos de roupa e cores de cabelo diferentes.",
        slug: "beijokinhas",
        logo: "products/beijokinhas/logo.png",
        lamina: ["products/beijokinhas/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/beijokinhas/box.png"
            }
        ],
        feature: "products/beijokinhas/featured.png",
        downloadUrl: "products/beijokinhas/beijokinhas.pdf",
        skus: [
            {
                "sku": "9802",
                "name": "Beijokinhas | Loira"
            },
            {
                "sku": "9803",
                "name": "Beijokinhas | Morena"
            },
            {
                "sku": "9536",
                "name": "Beijokinhas | Rosa Vestido"
            },
        ],
        info: {
            height: "30cm",
            measures: "16,5x8x36cm",
            fitsInBox: 9,
            weight: "2,8kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Cindy Frases",
        category: "girl",
        isNew: false,
        description: "A boneca Cindy fala diferetes frases e acompanha sapatos.",
        slug: "cindy-frases",
        logo: "products/cindy-frases/logo.png",
        lamina: ["products/cindy-frases/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/cindy-frases/box.png"
            }
        ],
        feature: "products/cindy-frases/featured.png",
        downloadUrl: "products/cindy-frases/cindy-frases.pdf",
        skus: [
            {
                "sku": "9010",
                "name": "Cindy Frases | Loira"
            },
            {
                "sku": "9011",
                "name": "Cindy Frases | Morena"
            }
        ],
        info: {
            height: "30cm",
            measures: "26,5x8,5x36cm",
            fitsInBox: 6,
            weight: "2,6kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Jujubinhas",
        category: "girl",
        isNew: false,
        description: "As bonecas Jujubinhas são macias e vem em 3 modelos diferentes: laranja, rosa e lilás",
        slug: "jujubinhas",
        logo: "products/jujubinhas/logo.png",
        lamina: ["products/jujubinhas/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/jujubinhas/box.png"
            }
        ],
        feature: "products/jujubinhas/featured.png",
        downloadUrl: "products/jujubinhas/jujubinhas.pdf",
        skus: [
            {
                "sku": "9414",
                "name": "Jujubinha | Rosa"
            }
        ],
        info: {
            height: "22cm",
            measures: "16x7x26cm",
            fitsInBox: 12,
            weight: "3,5kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Mini Princess",
        category: "girl",
        isNew: false,
        description: "Fofinhas e em 3 modelos diferentes, a Mini Princess é uma gracinha.",
        slug: "mini-princess",
        logo: "products/mini-princess/logo.png",
        lamina: ["products/mini-princess/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/mini-princess/box.png"
            }
        ],
        feature: "products/mini-princess/featured.png",
        downloadUrl: "products/mini-princess/mini-princess.pdf",
        skus: [
            {
                "sku": "9401",
                "name": "Mini Princess | Coroa"
            }
        ],
        info: {
            height: "21cm",
            measures: "16x7x26cm",
            fitsInBox: 12,
            weight: "3,5kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Bebelzinhas",
        category: "girl",
        isNew: false,
        description: "As Bebelzinhas são fofinhas e acompanham chupeta e fraldinha. Em 2 modelos: a negra e a branca.",
        slug: "bebelzinhas",
        logo: "products/bebelzinhas/logo.png",
        lamina: ["products/bebelzinhas/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/bebelzinhas/box.png"
            }
        ],
        feature: "products/bebelzinhas/featured.png",
        downloadUrl: "products/bebelzinhas/bebelzinhas.pdf",
        skus: [
            {
                "sku": "9203",
                "name": "Coleção Bebelzinhas | Branca"
            },
            {
                "sku": "9206",
                "name": "Coleção Bebelzinhas | Negra"
            }
        ],
        info: {
            height: "21cm",
            measures: "16x7x26cm",
            fitsInBox: 12,
            weight: "3,5kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Amigas Para Sempre",
        category: "girl",
        isNew: false,
        description: "Fala diversas frases e acompanha uma linda bolsinha. Sua roupa é um charme.",
        slug: "amigas-para-sempre",
        logo: "products/amigas-para-sempre/logo.png",
        lamina: ["products/amigas-para-sempre/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/amigas-para-sempre/box.png"
            }
        ],
        feature: "products/amigas-para-sempre/featured.png",
        downloadUrl: "products/amigas-para-sempre/amigas-para-sempre.pdf",
        skus: [
            {
                "sku": "3001",
                "name": "Amigas Para Sempre | Déda"
            }
        ],
        info: {
            height: "60cm",
            measures: "31x12x64,5cm",
            fitsInBox: 4,
            weight: "5,6kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Emelly Frases",
        category: "girl",
        isNew: false,
        description: "A boneca Emelly fala diferentes frases e vem em 2 modelos apra escolher: loira e morena.",
        slug: "emelly-frases",
        logo: "products/emelly-frases/logo.png",
        lamina: ["products/emelly-frases/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/emelly-frases/box.png"
            }
        ],
        feature: "products/emelly-frases/featured.png",
        downloadUrl: "products/emelly-frases/emelly-frases.pdf",
        skus: [
            {
                "sku": "9900",
                "name": "Emelly Frases | Loira"
            }
        ],
        info: {
            height: "40cm",
            measures: "30x11x53cm",
            fitsInBox: 6,
            weight: "3,9kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Nani Fashion",
        category: "girl",
        isNew: false,
        description: "A estilosa Nani Fashion acompanha vários acessóriose um lindo sapatinho para compor o visual.",
        slug: "nani-fashion",
        logo: "products/nani-fashion/logo.png",
        lamina: ["products/nani-fashion/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/nani-fashion/box.png"
            }
        ],
        feature: "products/nani-fashion/featured.png",
        downloadUrl: "products/nani-fashion/nani-fashion.pdf",
        skus: [
            {
                "sku": "9920",
                "name": "Nani Fashion | Loira"
            }
        ],
        info: {
            height: "40cm",
            measures: "30x11x53cm",
            fitsInBox: 6,
            weight: "4,6kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Pequena Flor",
        category: "girl",
        isNew: false,
        description: "As bonecas Pequena Flor vem em 3 modelos e acompanham sapatinhos bem fofos.",
        slug: "pequena-flor",
        logo: "products/pequena-flor/logo.png",
        lamina: ["products/pequena-flor/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/pequena-flor/box.png"
            }
        ],
        feature: "products/pequena-flor/featured.png",
        downloadUrl: "products/pequena-flor/pequena-flor.pdf",
        skus: [
            {
                "sku": "9001",
                "name": "Pequena Flor | Yasmin"
            },
            {
                "sku": "9002",
                "name": "Pequena Flor | Dália"
            }
        ],
        info: {
            height: "30cm",
            measures: "16,5x8x36cm",
            fitsInBox: 9,
            weight: "2,9kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Mon Amour",
        category: "girl",
        isNew: false,
        description: "A Mon Amour é macia, vem com um lindo vestido e sapatinhos.",
        slug: "mon-amour",
        logo: "products/mon-amour/logo.png",
        lamina: ["products/mon-amour/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/mon-amour/box.png"
            }
        ],
        feature: "products/mon-amour/featured.png",
        downloadUrl: "products/mon-amour/mon-amour.pdf",
        skus: [
            {
                "sku": "9589",
                "name": "Mon Amour | Loira"
            },
            {
                "sku": "9590",
                "name": "Mon Amour | Negra"
            },
        ],
        info: {
            height: "55cm",
            measures: "31x12x64,5cm",
            fitsInBox: 4,
            weight: "5,6kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Bella Baby",
        category: "girl",
        isNew: false,
        description: "A Bella Baby canta música e acompanha uma linda chupeta.",
        slug: "bella-baby",
        logo: "products/bella-baby/logo.png",
        lamina: ["products/bella-baby/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/bella-baby/box.png"
            }
        ],
        feature: "products/bella-baby/featured.png",
        downloadUrl: "products/bella-baby/bella-baby.pdf",
        skus: [
            {
                "sku": "9910",
                "name": "Bella Baby"
            }
        ],
        info: {
            height: "40cm",
            measures: "30x11x53cm",
            fitsInBox: 6,
            weight: "4,1kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Meu Amorzinho",
        category: "girl",
        isNew: false,
        description: "A boneca Meu Amorzinho vem com diversos acessórios e uma faixa para colocar na cabeça.",
        slug: "meu-amorzinho",
        logo: "products/meu-amorzinho/logo.png",
        lamina: ["products/meu-amorzinho/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/meu-amorzinho/box.png"
            }
        ],
        feature: "products/meu-amorzinho/featured.png",
        downloadUrl: "products/meu-amorzinho/meu-amorzinho.pdf",
        skus: [
            {
                "sku": "9301",
                "name": "Meu Amorzinho"
            }
        ],
        info: {
            height: "40cm",
            measures: "30x11x53cm",
            fitsInBox: 6,
            weight: "4,2kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Mon Amour Frases",
        category: "girl",
        isNew: false,
        description: "A Mon Amour é macia, vem com um lindo vestido e sapatinhos e fala diferentes frases.",
        slug: "mon-amour-frases",
        logo: "products/mon-amour-frases/logo.png",
        lamina: ["products/mon-amour-frases/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/mon-amour-frases/box.png"
            }
        ],
        feature: "products/mon-amour-frases/featured.png",
        downloadUrl: "products/mon-amour-frases/mon-amour-frases.pdf",
        skus: [
            {
                "sku": "7007",
                "name": "Mon Amour Frases | Loira"
            },
            {
                "sku": "7008",
                "name": "Mon Amour Frases | Morena"
            },
            {
                "sku": "7003",
                "name": "Mon Amour Frases | Negra"
            },
        ],
        info: {
            height: "55cm",
            measures: "31x12x64,5cm",
            fitsInBox: 4,
            weight: "5,7kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Bella Frases",
        category: "girl",
        isNew: false,
        description: "Em 2 modelos, a Bella Frases fala várias frases e acompanha sapatinhos.",
        slug: "bella-frases",
        logo: "products/bella-frases/logo.png",
        lamina: ["products/bella-frases/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/bella-frases/box.png"
            }
        ],
        feature: "products/bella-frases/featured.png",
        downloadUrl: "products/bella-frases/bella-frases.pdf",
        skus: [
            {
                "sku": "9701",
                "name": "Bella Frases | Loira"
            }
        ],
        info: {
            height: "40cm",
            measures: "30x11x53cm",
            fitsInBox: 6,
            weight: "4,7kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "doll"]
    },
    {
        name: "Dino History Species Dedoches",
        category: "boy",
        isNew: false,
        description: "Faça a era dos dinossauros caber na palma da sua mão com Dedoche DinoHistory Species.",
        slug: "dino-history-species-dedoches",
        logo: "products/dino-history-species-dedoches/logo.png",
        lamina: ["products/dino-history-species-dedoches/lamina.png"],
        box: [
            {
                "page": 1,
                "image": "products/dino-history-species-dedoches/box.png"
            }
        ],
        feature: "products/dino-history-species-dedoches/featured.png",
        downloadUrl: "products/dino-history-species-dedoches/dino-history-species-dedoches.pdf",
        skus: [
            {
                "sku": "9594",
                "name": "Dino History | Species Dedoches"
            }
        ],
        info: {
            height: "7cm",
            measures: "23,5x24,5cm",
            fitsInBox: 16,
            weight: "1,8kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "dinosaur"]
    },
    {
        name: "Dino History Explorer Dedoches",
        category: "boy",
        isNew: false,
        description: "Liberte a fera em suas mãos com dedoche DinoHistory Explorer.",
        slug: "dino-history-explorer-dedoches",
        logo: "products/dino-history-explorer-dedoches/logo.png",
        lamina: ["products/dino-history-explorer-dedoches/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/dino-history-explorer-dedoches/box.png"
            }
        ],
        feature: "products/dino-history-explorer-dedoches/featured.png",
        downloadUrl: "products/dino-history-explorer-dedoches/dino-history-explorer-dedoches.pdf",
        skus: [
            {
                "sku": "9593",
                "name": "Dino History | Explorer Dedoches"
            }
        ],
        info: {
            height: "8cm",
            measures: "23,5x24,5cm",
            fitsInBox: 16,
            weight: "1,8kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "dinosaur"]
    },
    {
        name: "Gorilla Mega Monster",
        category: "boy",
        isNew: false,
        description: "Super fera, para você brincar!",
        slug: "gorilla-mega-monster",
        logo: "products/gorilla-mega-monster/logo.png",
        lamina: [
            "products/gorilla-mega-monster/lamina-1.png"
        ],
        box: [
            {
                "page": 1,
                "image":  "products/gorilla-mega-monster/box-1.png"
            },
            {
                isNew: true,
                "page": 1,
                "image":  "products/gorilla-mega-monster/box-2.png"
            }
        ],
        feature: "products/gorilla-mega-monster/featured.png",
        downloadUrl: "products/gorilla-mega-monster/gorilla-mega-monster.pdf",
        skus: [
            {
                "sku": "9585",
                "name": "Gorilla Mega Monster",
                info: {
                    height: "22cm",
                    measures: "20,5x9x25cm",
                    fitsInBox: 12,
                    weight: "5,8kg"
                }
            },
            {
                "sku": "9592",
                "name": "Gorilla Mega Monster Cinta",
                info: {
                    height: "22cm",
                    measures: "28x7x15cm",
                    fitsInBox: 9,
                    weight: "2,8kg"
                }
            }
        ],
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "adventure"]
    },
    {
        name: "Battle Gorilla X Dino",
        category: "boy",
        isNew: false,
        description: "Que a brincadeira comece!!! Quem ẽ o mais forte? Rex ou Gorila? Essa batalha promete! SUUUPER LEGAL!",
        slug: "battle-gorilla-dino",
        logo: "products/battle-gorilla-dino/logo.png",
        lamina: ["products/battle-gorilla-dino/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/battle-gorilla-dino/box.png"
            }
        ],
        feature: "products/battle-gorilla-dino/featured.png",
        downloadUrl: "products/battle-gorilla-dino/battle-gorilla-dino.pdf",
        skus: [
            {
                "sku": "9584",
                "name": "Battle Gorilla X Dino"
            }
        ],
        info: {
            height: "22cm",
            measures: "40,5x9x25cm",
            fitsInBox: 6,
            weight: "4,3kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "adventure", "dinosaur"]
    },
    {
        name: "Dino Júnior Painter",
        category: "boy",
        isNew: false,
        description: "Pura Diversão!! Dino Junior Painter, vem com tinta guache para poder pintar o seu Dino!!",
        slug: "dino-junior-painter",
        logo: "products/dino-junior-painter/logo.png",
        lamina: ["products/dino-junior-painter/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/dino-junior-painter/box.png"
            }
        ],
        feature: "products/dino-junior-painter/featured.png",
        downloadUrl: "products/dino-junior-painter/dino-junior-painter.pdf",
        skus: [
            {
                "sku": "9587",
                "name": "Dino Júnior Painter"
            }
        ],
        info: {
            height: "19cm",
            measures: "29,5x11x20,5cm",
            fitsInBox: 8,
            weight: "3,7kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "dinosaur"]
    },
    {
        name: "Dino Júnior Surpresa",
        category: "boy",
        isNew: false,
        description: "Vem com BRINDE SURPRESA!",
        slug: "dino-junior-surpresa",
        logo: "products/dino-junior-surpresa/logo.png",
        lamina: ["products/dino-junior-surpresa/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/dino-junior-surpresa/box.png"
            }
        ],
        feature: "products/dino-junior-surpresa/featured.png",
        downloadUrl: "products/dino-junior-surpresa/dino-junior-surpresa.pdf",
        skus: [
            {
                "sku": "9586",
                "name": "Dino Júnior Surpresa"
            }
        ],
        info: {
            height: "19cm",
            measures: "29,5x11x20,5cm",
            fitsInBox: 8,
            weight: "3,4kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "dinosaur"]
    },
    {
        name: "Liga Power",
        category: "boy",
        isNew: false,
        description: "Bonecos Liga Power em 4 modelos diferentes. Difícil vai ser escolher 1 só.",
        slug: "liga-power",
        logo: "products/liga-power/logo.png",
        lamina: ["products/liga-power/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/liga-power/box.png"
            }
        ],
        feature: "products/liga-power/featured.png",
        downloadUrl: "products/liga-power/liga-power.pdf",
        skus: [
            {
                "sku": "9582",
                "name": "Liga Power | Solapa",
                info: {
                    height: "34,5cm",
                    measures: "-",
                    fitsInBox: 24,
                    weight: "4,5kg"
                },
            },
            {
                "sku": "9581",
                "name": "Liga Power | Caixa"
            }
        ],
        info: {
            height: "34,5cm",
            measures: "16,5x7x37cm",
            fitsInBox: 12,
            weight: "3,8kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "adventure"]
    },
    {
        name: "Dino Painter",
        category: "boy",
        isNew: false,
        description: "Divertido dinossauro em vinil para pintar com tinta guache, acompanha 3 tintas e pincel",
        slug: "dino-painter",
        logo: "products/dino-painter/logo.png",
        lamina: ["products/dino-painter/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/dino-painter/box.png"
            }
        ],
        feature: "products/dino-painter/featured.png",
        downloadUrl: "products/dino-painter/dino-painter.pdf",
        skus: [
            {
                "sku": "9580",
                "name": "Dino Painter"
            }
        ],
        info: {
            height: "26cm",
            measures: "26x8x20cm",
            fitsInBox: 12,
            weight: "4,5kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "dinosaur"]
    },
    {
        name: "Dino Car",
        category: "boy",
        isNew: false,
        description: "Um super carrinho em formato de dinossauro feito completamente de vinil.",
        slug: "dino-car",
        logo: "products/dino-car/logo.png",
        lamina: ["products/dino-car/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/dino-car/box.png"
            }
        ],
        feature: "products/dino-car/featured.png",
        downloadUrl: "products/dino-car/dino-car.pdf",
        skus: [
            {
                "sku": "9564",
                "name": "Dino Car"
            }
        ],
        info: {
            height: "12cm",
            measures: "18x9,5x12cm",
            fitsInBox: 9,
            weight: "2,0kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "car", "dinosaur"]
    },
    {
        name: "Era dos Dragões",
        category: "boy",
        isNew: false,
        description: "Dragões feitos em vinil, com efeitos sonoros e em 3 cores diferentes.",
        slug: "era-dos-dragoes",
        logo: "products/era-dos-dragoes/logo.png",
        lamina: ["products/era-dos-dragoes/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/era-dos-dragoes/box.png"
            }
        ],
        feature: "products/era-dos-dragoes/featured.png",
        downloadUrl: "products/era-dos-dragoes/era-dos-dragoes.pdf",
        skus: [
            {
                "sku": "9543",
                "name": "Era dos Dragões | Azul"
            },
            {
                "sku": "9544",
                "name": "Era dos Dragões | Preto"
            },
            {
                "sku": "9545",
                "name": "Era dos Dragões | Vermelho"
            },
        ],
        info: {
            height: "38cm",
            measures: "41,5x12x29cm",
            fitsInBox: 6,
            weight: "5,3kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "adventure"]
    },
    {
        name: "Dinossauros Adventure",
        category: "boy",
        isNew: false,
        description: "Diversos modelos de dinossauros em vinil, super detalhados e vendidos na cinta ou na caixa.",
        slug: "dinossauros-adventure",
        logo: "products/dinossauros-adventure/logo.png",
        skuPageChange: true,
        lamina: [
            "products/dinossauros-adventure/lamina-1.png",
            "products/dinossauros-adventure/lamina-2.png",
            "products/dinossauros-adventure/lamina-3.png",
            "products/dinossauros-adventure/lamina-4.png",
            "products/dinossauros-adventure/lamina-5.png",
            "products/dinossauros-adventure/lamina-6.png",
            "products/dinossauros-adventure/lamina-7.png",
            "products/dinossauros-adventure/lamina-8.png",
            "products/dinossauros-adventure/lamina-9.png"
        ],
        box: [
            {
                "page": 1,
                "image": "products/dinossauros-adventure/box-rexmini-cinta.png",
                "name": "Rex Mini Cinta"
            },
            {
                "page": 1,
                "image": "products/dinossauros-adventure/box-velociraptor-cinta.png",
                "name": "Velociraptor Cinta"
            },
            {
                "page": 1,
                "image": "products/dinossauros-adventure/box-rex-cinta.png",
                "name": "Rex Cinta"
            },
            {
                "page": 2,
                "image": "products/dinossauros-adventure/box-estegoussauro-cinta.png",
                "name": "Estegossauro Cinta"
            },
            {
                "page": 2,
                "image": "products/dinossauros-adventure/box-triceraptops-cinta.png",
                "name": "Triceraptops Cinta"
            },
            {
                "page": 2,
                "image": "products/dinossauros-adventure/box-braquiossauro-cinta.png",
                "name": "Braquiossauro Cinta"
            },
            {
                "page": 3,
                "image": "products/dinossauros-adventure/box-estegossauro.png",
                "name": "Estegossauro"
            },
            {
                "page": 3,
                "image": "products/dinossauros-adventure/box-triceratops.png",
                "name": "Triceraptops"
            },
            {
                "page": 4,
                "image": "products/dinossauros-adventure/box-braquiossauro.png",
                "name": "Braquiossauro"
            },
            {
                "page": 4,
                "image": "products/dinossauros-adventure/box-velociraptor.png",
                "name": "Velociraptor"
            },
            {
                "page": 4,
                "image": "products/dinossauros-adventure/box-rexmini.png",
                "name": "Rex Mini"
            },
            {
                "page": 5,
                "image": "products/dinossauros-adventure/box-rex.png",
                "name": "Rex"
            },
            {
                "page": 6,
                "image": "products/dinossauros-adventure/box-rexmecanismo.png",
                "name": "Rex Mecanismo"
            },
            {
                "page": 7,
                "image": "products/dinossauros-adventure/box-braquiossauromoto-kit.png",
                "name": "Kit Braquiossauro Moto"
            },
            {
                "page": 8,
                "image": "products/dinossauros-adventure/box-triceratops-kit.png",
                "name": "Kit Triceratops"
            },
            {
                "page": 9,
                "image": "products/dinossauros-adventure/box-rexmini-kit.png",
                "name": "Kit Rex Mini"
            },
            {
                "page": 9,
                "image": "products/dinossauros-adventure/box-braquiossaurojeep-kit.png",
                "name": "Kit Braquiossauro Jeep"
            }
        ],
        feature: "products/dinossauros-adventure/featured.png",
        downloadUrl: "products/dinossauros-adventure/dinossauros-adventure.zip",
        skus: [
            {
                "sku": "9557",
                "name": "Rex Mini Cinta",
                info: {
                    height: "26,5cm",
                    measures: "28x6x20cm",
                    fitsInBox: 12,
                    weight: "2,1kg"
                },
                "page": 1
            },
            {
                "sku": "9559",
                "name": "Velociraptor Cinta",
                info: {
                    height: "22cm",
                    measures: "25x5,5x20cm",
                    fitsInBox: 12,
                    weight: "1,6kg"
                },
                "page": 1
            },
            {
                "sku": "9563",
                "name": "Rex Cinta",
                info: {
                    height: "41cm",
                    measures: "41x9x27cm",
                    fitsInBox: 9,
                    weight: "3,5kg"
                },
                "page": 1
            },
            {
                "sku": "9558",
                "name": "Estegossauro Cinta",
                info: {
                    height: "23cm",
                    measures: "20,5x6x17cm",
                    fitsInBox: 12,
                    weight: "1,5kg"
                },
                "page": 2
            },
            {
                "sku": "9561",
                "name": "Triceraptops Cinta",
                info: {
                    height: "21cm",
                    measures: "19x6x15cm",
                    fitsInBox: 12,
                    weight: "1,4kg"
                },
                "page": 2
            },
            {
                "sku": "9560",
                "name": "Braquiossauro Cinta",
                info: {
                    height: "29cm",
                    measures: "29x6x17cm",
                    fitsInBox: 12,
                    weight: "1,8kg"
                },
                "page": 2
            },
            {
                "sku": "9555",
                "name": "Estegossauro",
                info: {
                    height: "23cm",
                    measures: "23x8x14,5cm",
                    fitsInBox: 12,
                    weight: "3,0kg"
                },
                "page": 3
            },
            {
                "sku": "8004",
                "name": "Triceraptops",
                info: {
                    height: "21cm",
                    measures: "26x8x14,5cm",
                    fitsInBox: 12,
                    weight: "3,0kg"
                },
                "page": 3
            },
            {
                "sku": "8005",
                "name": "Braquiossauro",
                info: {
                    height: "29cm",
                    measures: "26x8x20cm",
                    fitsInBox: 12,
                    weight: "3,5kg"
                },
                "page": 4
            },
            {
                "sku": "9556",
                "name": "Velociraptor",
                info: {
                    height: "22cm",
                    measures: "26x8x20cm",
                    fitsInBox: 12,
                    weight: "3,6kg"
                },
                "page": 4
            },
            {
                "sku": "9554",
                "name": "Rex Mini",
                info: {
                    height: "29cm",
                    measures: "26x8x20cm",
                    fitsInBox: 12,
                    weight: "3,5kg"
                },
                "page": 4
            },
            {
                "sku": "8000",
                "name": "Rex",
                info: {
                    height: "41cm",
                    measures: "29x12x41,5cm",
                    fitsInBox: 6,
                    weight: "5,1kg"
                },
                "page": 5
            },
            {
                "sku": "8001",
                "name": "Rex Mecanismo",
                info: {
                    height: "41cm",
                    measures: "29x12x41,5cm",
                    fitsInBox: 6,
                    weight: "4,6kg"
                },
                "page": 6
            },
            {
                "sku": "8002",
                "name": "Kit Braquiossauro Moto",
                info: {
                    height: "29cm",
                    measures: "34x8x24cm",
                    fitsInBox: 8,
                    weight: "3,5kg"
                },
                "page": 7
            },
            {
                "sku": "8003",
                "name": "Kit Triceratops",
                info: {
                    height: "21cm",
                    measures: "34x8x24cm",
                    fitsInBox: 8,
                    weight: "3,2kg"
                },
                "page": 8
            },
            {
                "sku": "9562",
                "name": "Kit Rex Mini",
                info: {
                    height: "26,5cm",
                    measures: "34x8x24cm",
                    fitsInBox: 8,
                    weight: "3,8kg"
                },
                "page": 9
            },
            {
                "sku": "8008",
                "name": "Kit Braquiossauro Jeep",
                info: {
                    height: "29cm",
                    measures: "34x12x24cm",
                    fitsInBox: 8,
                    weight: "4,4kg"
                },
                "page": 9
            }
        ],
        /*info: {
            height: "??cm",
            measures: "??cm",
            fitsInBox: 9999,
            weight: "??kg"
        },*/
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "dinosaur"]
    },

    {
        name: "Hero Max",
        category: "boy",
        isNew: false,
        description: "Feitos em vinil, bonecos articulados e acompanham divertidos acessórios",
        slug: "hero-max",
        logo: "products/hero-max/logo.png",
        lamina: ["products/hero-max/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/hero-max/box.png"
            }
        ],
        feature: "products/hero-max/featured.png",
        downloadUrl: "products/hero-max/hero-max.pdf",
        skus: [
            {
                "sku": "5205",
                "name": "Hero Max"
            }
        ],
        info: {
            height: "30cm",
            measures: "24x5,5x34cm",
            fitsInBox: 12,
            weight: "5,5kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "adventure"]
    },
    {
        name: "New Powers",
        category: "boy",
        isNew: false,
        description: "Os bonecos New Powers são macios, feitos em vinil e em 4 modelos totalmente articulados.",
        slug: "new-powers",
        logo: "products/new-powers/logo.png",
        lamina: ["products/new-powers/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/new-powers/box.png"
            }
        ],
        feature: "products/new-powers/featured.png",
        downloadUrl: "products/new-powers/new-powers.pdf",
        skus: [
            {
                "sku": "9546",
                "name": "New Powers | Azul"
            },
            {
                "sku": "9547",
                "name": "New Powers | Branco"
            },
            {
                "sku": "9548",
                "name": "New Powers | Amarelo"
            },
            {
                "sku": "9552",
                "name": "New Powers | Hero"
            }
        ],
        info: {
            height: "30cm",
            measures: "12,5x5,5x33,5cm",
            fitsInBox: 12,
            weight: "3,8kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "adventure"]
    },
    {
        name: "Extreme Moto Cross",
        category: "boy",
        isNew: false,
        description: "Kit boneco e moto, rico em detalhes e em diversão.",
        slug: "extreme-moto-cross",
        logo: "products/extreme-moto-cross/logo.png",
        lamina: ["products/extreme-moto-cross/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/extreme-moto-cross/box.png"
            }
        ],
        feature: "products/extreme-moto-cross/featured.png",
        downloadUrl: "products/extreme-moto-cross/extreme-moto-cross.pdf",
        skus: [
            {
                "sku": "8006",
                "name": "Extreme Moto Cross",
            }
        ],
        info: {
            height: "14cm",
            measures: "26x8x14,5cm",
            fitsInBox: 12,
            weight: "2,3kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "car"]
    },
    {
        name: "Motocross ZT-250",
        category: "boy",
        isNew: false,
        description: "Motocross com boneco em vinil e feito pra ser super radical.",
        slug: "motocross",
        logo: "products/motocross/logo.png",
        lamina: ["products/motocross/lamina.png"],
        box: [
            {
                "page": 1,
                "image":  "products/motocross/box.png"
            }
        ],
        feature: "products/motocross/featured.png",
        downloadUrl: "products/motocross/motocross.pdf",
        skus: [
            {
                "sku": "9568",
                "name": "Motocross"
            }
        ],
        info: {
            height: "19,5cm",
            measures: "20x8x26cm",
            fitsInBox: 12,
            weight: "2,9kg"
        },
        related: [
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            },
            {
                "name": "??",
                "slug": "/produtos/??",
                "box": "products/aaaaaaaaa/box.png"
            }
        ],
        categories: ["all", "car"]
    }
]