import * as React from "react"
import Main from "../components/Layout/Main";
import productsData from "../theme/products";
import categoriesData from "../theme/categories";
import "../styles/pages/index.scss"
import Breadcrumb from "../components/Layout/Breadcrumb";
import {Link} from "gatsby";
import Image from "../components/Image";
import Seo from "../components/Seo";
import {useState} from "react";

const ProdutosPage = () => {
    const [activeCategory, setActiveCategory] = useState(categoriesData.categories[0].slug)
    const [products, setProducts] = useState(productsData.products)

    const handleCategory = (slug) => {
        setActiveCategory(slug)
        setProducts(productsData.products.filter(function(el){
            return el.categories.includes(slug)
        }))
    }

    return (
        <Main>
            <Seo title="Produtos" description="Confira nossos Produtos. Temos uma diversidade muito grande de produtos para atendê-lo."/>
            <Breadcrumb title="Produtos"/>

            <div className="relative my-10 overflow-x-auto">
                <ul className="inline-flex justify-center lg:flex lg:m-auto">
                    {
                        categoriesData.categories.map((category, key) => {
                            return(
                                <li className="cursor-pointer" onClick={() => handleCategory(category.slug)}>
                                    <div className={`rounded-full overflow-hidden m-2 border-2 transition hover:border-darkpurple animate hover:scale-125 ${activeCategory === category.slug?'border-darkpurple':'border-transparent'}`}>
                                        <div className={`w-28 h-28 flex rounded-full bg-primary p-4 m-1 transition`}>
                                            <img loading="lazy" src={category.icon} alt={category.name} title={category.name}
                                                 className={`w-20 h-20 hover:fill-current hover:fill brightness-0 invert`}/>
                                        </div>
                                    </div>
                                    <p className="text-center text-darkpurple font-bold uppercase ">{category.name}</p>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <div className="grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                {
                    products.map((product, key) =>
                        product.box.map((box, boxKey) => {
                            return(
                                <Link to={'/produtos/'+product.slug} state={{page: box.page, boxKey: boxKey}} key={key.toString()+boxKey.toString()} className="p-6 bg-gray-100 flex flex-col relative">
                                    <Image loading="lazy" src={product.logo} alt={box.name??product.name} title={box.name??product.name} className="h-28 absolute" />
                                    <p className="flex-1 flex align-middle">
                                        <Image loading="lazy" src={box.image} alt={box.name??product.name} title={box.name??product.name} className="min-w-9/12 mx-auto mt-10 self-center max-h-80" />
                                    </p>
                                    <p className="text-lg font-bold text-center pt-3 pb-7">{box.name??product.name}</p>
                                    { (product.isNew || box.isNew) && (
                                        <div className={`text-sm bg-primary py-1 px-2 border rounded-full border-b-2 border-r-2 font-bold absolute bottom-32 right-10 transform -rotate-6
                                        ${product.category === 'boy'?" border-darkpurple text-darkblue":" border-darkpurple text-purple"}`}>LANÇAMENTO</div>
                                    )}
                                </Link>
                            )
                        })
                    )
                }
            </div>
        </Main>
    )
}

export default ProdutosPage
